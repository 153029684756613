/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '../node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '../node_modules/admin-lte/dist/css/adminlte.min.css';
@import '../node_modules/admin-lte/plugins/select2/css/select2.min.css';
@import '../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css';
@import '../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
@import '../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css';
@import '../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css';
@import '../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css';
@import '../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css';

@import 'ngx-toastr/toastr';

body {
    width: 100vw;
    height: 100vh;
}
html, body { height: 100%; }
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-sort-header-container {
    justify-content: center !important;
    align-items: center !important;
    vertical-align: middle !important;
}

.mat-mdc-slide-toggle{
    margin-bottom: 20px;
}

pdf-viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(5px 5px 5px #222222);
  }

mat-form-field{
    width: 100% !important;
}

mat-radio-button div label, mat-checkbox div label{
    margin-bottom: 0px !important;
}


$enable-shadows: true;
$prefix: "mo-";

// Then import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

.content {
    padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
    overflow: hidden;
    display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
    transform: none;
}


.panel-fullscreen {
display: block;
z-index: 9999;
position: fixed;
width: 100%;
height: 100%;
top: 0;
right: 0;
left: 0;
bottom: 0;
overflow: auto;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.9) !important;
}

.form-dialog .form-group{
    margin-top: -15px !important;
}

.modal-title{
    background-color: #343A40; 
    color: white !important;
}

.form-dialog{
    margin-top: 30px !important; 
    margin-bottom: -20px;
}

.tabla td,.tabla th {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    vertical-align: middle !important;
} 

.tabla th{
    background-color: #343A40 !important; 
    color: white;
}
