@use '@angular/material' as mat;

.tablaPond th {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    vertical-align: middle !important;
    background-color: #343A40 !important; 
    color: white;
} 

@mixin color($theme) {
    .accent{
        color: mat.get-theme-color($theme, primary);;
    }
  }

  @mixin theme($theme) {
    @if mat.theme-has($theme, color) {
      @include color($theme);
    }
  }
