@use '@angular/material' as mat;
@use '../app/pages/visitas/formulario-ponderacion/formulario-ponderacion.component.scss' as ponderacion;

@include mat.core();

// Define a dark theme
$dark-theme: mat.define-dark-theme((
 color: (
   primary: mat.define-palette(mat.$purple-palette),
   accent: mat.define-palette(mat.$teal-palette),
   warn: mat.define-palette(mat.$red-palette)
 ),
  // Only include `typography` and `density` in the default dark theme.
  typography: mat.define-typography-config(),
  density: 0,
));

// Define a light theme
$light-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette(mat.$indigo-palette),
   accent: mat.define-palette(mat.$pink-palette),
   warn: mat.define-palette(mat.$red-palette)
 ),
));

//@include mat.core-color($light-theme);
@include mat.all-component-themes($light-theme);
@include ponderacion.theme($light-theme);

.dark-mode {
  //@include mat.core-theme($dark-theme);
  @include mat.all-component-colors($dark-theme);
  @include ponderacion.theme($dark-theme);
}